import React from 'react';
import Layout from '../components/Layout';
import Sidebar from '../components/SidebarMFE';
import Classes from './publications.module.css';
import config from '../../config';
import awsbadgesaa from '../assets/images/AWS-SolArchitect-Professional-2020.png';
import Nav from '../assets/css_modules/Navigation.module.css';
import Navigation from '../components/Navigation';
import FrontendStyles from '../assets/css_modules/publications/Frontend/Frontend.module.css';
import { Link } from 'gatsby';
import microfrontendsLogo from '../assets/images/MicroFrontends Logo 2.png';

const Publications = () => {
  return (
    <Layout content="Microfrontends" title="Microfrontends">
      <Sidebar showTabs="microfrontends" />

      <div>
        <nav className={Classes.TopNav}>
          <div className={Classes.Row}>
            <ul className={Classes.MainNav}>
              <li> </li>
              <li className="nav-item">
                {' '}
                <Link to="/"> Author</Link>
              </li>
              <li className="nav-item">
                <Link to="/microfrontends">Micro-Frontends</Link>
              </li>
              <li className="nav-item">
                <Link to="/publications">Publications</Link>
              </li>
            </ul>
          </div>
        </nav>

        <header className={Classes.Publications} id="microfrontends">
          <div className={Classes.TextBox}>
            <h1 style={{ color: 'white' }}>Micro-Frontends</h1>
            {'    '}
            <p> All you need to learn and implement Micro-Frontends</p>
            <a
              className={Classes.Links}
              target="blank"
              href="https://www.linkedin.com/pulse/micro-frontends-from-begining-expert-rany-elhousieny-phd%E1%B4%AC%E1%B4%AE%E1%B4%B0/"
            >
              LinkedIn
            </a>{' '}
            <a
              target="blank"
              href="https://javascript.plainenglish.io/micro-frontends-from-the-begining-to-expert-33d660a05bb5"
              className={Classes.Links}
            >
              Medium
            </a>{' '}
            <a
              className={Classes.Links}
              target="blank"
              href="https://youtube.com/playlist?list=PLjzEd-em7iW_-cUUki8Zt15mGF6ZrOQPn"
            >
              YouTube
            </a>{' '}
            <a
              className={Classes.Links}
              target="blank"
              href="https://github.com/ranyelhousieny/react-microfrontends"
            >
              Github
            </a>
            <Link className={Classes.Links} target="blank" href="/">
              Home
            </Link>
            <p />
            <div className="social-icons">
              {config.microfrontends.map(social => {
                const { icon, url } = social;
                return (
                  <a key={url} href={url} target="_blank">
                    <i className={`fab ${icon}`} />
                  </a>
                );
              })}
            </div>
          </div>
        </header>
        <section
          id="definition"
          className="resume-section p-3 p-lg-5 d-flex justify-content-center"
        >
          <div>
            <h2>What are Micro-Frontends?</h2>
            {'    '}
            <p className={Classes.ParBox}>
              <span style={{ color: 'orange' }}> Martin Fowler </span>defined
              the Micro-Frontend architecture as "an architectural style where
              independently deliverable frontend applications are composed into
              a greater whole". Simply, a{' '}
              <span style={{ color: 'orange' }}>Micro-Frontend </span>is a
              portion of a webpage (not the entire page). In the Micro-Frontend
              Architecture, there is a{' '}
              <span style={{ color: 'orange' }}>"Host" </span>or a{' '}
              <span style={{ color: 'orange' }}>"Container"</span>
              page that can host one or more Micro-Frontends. The Host/Container
              page can also share some of its own Micro-Frontend components. For
              example, as we implemented in our previous{' '}
              <a
                href="https://www.linkedin.com/pulse/micro-frontends-from-begining-expert-rany-elhousieny-phd%E1%B4%AC%E1%B4%AE%E1%B4%B0/"
                target="blank"
              >
                articles
              </a>
              , we exposed a Button component from mfe1 page into mfe2 (the
              host/container) Or the live one at{' '}
              <a
                href="http://mfe1.s3-website-us-east-1.amazonaws.com/"
                target="blank"
              >
                http://mfe1.s3-website-us-east-1.amazonaws.com/
              </a>{' '}
              that we deployed together to S3 in this{' '}
              <a
                href="https://www.linkedin.com/pulse/deploying-micro-frontends-aws-step-using-gitlab-react-rany/"
                target="blank"
              >
                article
              </a>
              <p />
              <a
                href="https://www.linkedin.com/pulse/micro-frontends-from-begining-expert-rany-elhousieny-phd%E1%B4%AC%E1%B4%AE%E1%B4%B0/"
                target="blank"
              >
                Read More ...
              </a>
            </p>
          </div>
        </section>
        <section
          id="implementation"
          className="resume-section p-3 p-lg-5 justify-content-center"
        >
          <h2>Micro Frontends Implementation</h2>
          <p className={Classes.ParBox}>
            The main key in Micro-Frontends is the integration between the
            host/container and Micro-Frontends. There are two ways to integrate
            Micro-Frontends
          </p>
          <p />
          <h3>1. Build Time Integration</h3>
          <p className={Classes.ParBox}>
            This is what most of the code implemented today. The container will
            install the components as libraries, similar to the libraries you
            install from npm. The issues with this approach are syncing
            different versions of libraries and build issues. Also, it is very
            hard to use different technologies. Also, the size of the final
            package will be big because it contained all dependencies. Moreover,
            you have to deploy again for any changes in dependencies. Also,
            there is a tight coupling between the container and all
            Micro-Frontends.
          </p>
          <p />
          <h3>2. Run Time Integration</h3>
          <p className={Classes.ParBox}>
            Run-Time integration has three types of Compositions:
          </p>
          <h4>Server-Side Composition</h4>
          <p className={Classes.ParBox}>
            In this case, all the functionality will be in the backend that
            decides which Micro-Frontend to load. The server will decide which
            URL to route the request to. This is a standard server-side
            composition. I have used simple reverse-proxy using Nginx to do this
            task. However, there are many other implementations.
          </p>
          <h4>Edge-Side Composition</h4>
          <p className={Classes.ParBox}>
            In this approach, you make use of CDN (Ex: AWS CloudFront) and
            Lambda@Edge. The orchestration will happen on the CDN itself,
            instead of the Client-Side or Server-Side. More about this at {'  '}
            <a href="https://youtu.be/fT-5RHTtFNg" target="blank">
              https://youtu.be/fT-5RHTtFNg
            </a>{' '}
          </p>
          <h4>Client-Side Composition</h4>
          <p className={Classes.ParBox}>
            This is what I have explained in the previous{' '}
            <a
              href="https://www.linkedin.com/pulse/micro-frontends-hands-on-example-using-react-webpack-rany"
              target="blank"
            >
              articles
            </a>
            . In this case, the container is built and deployed separately. Each
            Micro-Frontend can be exposed as a separate package that the
            container/host can fetch the needed Micro-Frontend. For more
            explanation of this approach, refer to the list of articles{' '}
            <a
              href="https://www.linkedin.com/pulse/micro-frontends-from-begining-expert-rany-elhousieny-phd%25E1%25B4%25AC%25E1%25B4%25AE%25E1%25B4%25B0/?trackingId=r%2Fp%2FwxZBtEK14VGXdWfL%2BQ%3D%3D"
              target="blank"
            >
              here
            </a>
            . In this case, the container and the Micro-Frontends are completely
            de-coupled. They do not share build or deployment and can use
            different technologies. The container can decide on the version of
            the Micro-Frontend to deploy. One of the methods used, recently, is
            Webpack 5 Module Federation Plugin. Webpack 5 Module Federation
            Plugin has been trending very high and providing very successful
            results. If you need more details you can follow my other videos and
            articles here: <p />
            <a
              target="blank"
              href="https://www.linkedin.com/pulse/micro-frontends-from-begining-expert-rany-elhousieny-phd%E1%B4%AC%E1%B4%AE%E1%B4%B0/"
            >
              Articles
            </a>
            <p />
            <a target="blank" href="https://youtu.be/AZDDIgJSKU0">
              Videos
            </a>
            <p />
            <a
              href="https://www.linkedin.com/pulse/micro-frontends-from-begining-expert-rany-elhousieny-phd%E1%B4%AC%E1%B4%AE%E1%B4%B0/"
              target="blank"
            >
              Read More ...
            </a>
          </p>
          <p />
        </section>
        <section
          id="frameworks"
          className="resume-section p-3 p-lg-5 justify-content-center"
        >
          <h2>
            <a
              href="https://itnext.io/11-micro-frontends-frameworks-you-should-know-b66913b9cd20"
              target="blank"
            >
              Available Frameworks
            </a>
          </h2>
          <p>
            Micro-Frontends is new, and frameworks are evolving. There are
            several frameworks in the market today:
          </p>
          <ol>
            <li>
              <h3>
                <a
                  href="https://webpack.js.org/concepts/module-federation/"
                  target="blank"
                >
                  Webpack 5 Module Federation
                </a>
              </h3>
              <p className={Classes.ParBox}>
                Webpack is a well-known framework that is widely used in
                Frontend for years. Webpack added a new plugin for
                Micro-Frontends that solved most of the issues with previous
                frameworks. It does not require added overhead for systems that
                are already using Webpack. Webpack is an open-source JavaScript
                module bundler. Simply, Webpack takes modules with dependencies
                and generates static assets representing those modules that can
                be stored in a Web-enabled AWS S3 storage to have a website
                without servers. It can be used with Nginx to route URLs to the
                appropriate bucket on S3. It is very efficient as it reduces the
                need for servers and reduces the package size to only needed
                dependencies. In Micro-Frontends architecture, Webpack Module
                Federation allows JavaScript application to dynamically import
                code from another application at runtime from different URLs.
                Webpack provides Build-time and Run-time integrations without
                the need for a server. It also tackles the problem of code
                dependency and increased bundle size by enabling dependency
                sharing. For example, if you’re downloading a React component,
                your application won’t import React code twice. The module will
                smartly use the React source you already have and only import
                the component code. Finally, you can use React.lazy and
                React.suspense to provide a fallback should the imported code
                fail for some reason, making sure the user experience won’t be
                disrupted because of the failing build.
              </p>
            </li>
            <li>
              <h3>
                <a href="https://youtu.be/fT-5RHTtFNg" target="blank">
                  AWS Serverless Microfrontends @Edge
                </a>
              </h3>
              <p className={Classes.ParBox}>
                AWS Severless Micro-Frontends at the Edge: In this approach, AWS
                make use of their CDN (AWS CloudFront) and Lambda@Edge. The
                orchestration will happen on the CDN itself, instead of the
                Client-Side or Server-Side. More about this at {'  '}
                <a href="https://youtu.be/fT-5RHTtFNg" target="blank">
                  https://youtu.be/fT-5RHTtFNg
                </a>{' '}
              </p>
            </li>
            <li>
              <h3>
                <a href="https://bit.dev/" target="blank">
                  Bit
                </a>
              </h3>
              <p className={Classes.ParBox}>
                Bit is a Build-Time integration Framework. Bit provides a full
                framework, including CI/CD pipeline. It takes separate
                components and builds them into micro-frontends. Bit is similar
                to Webpack that it works in Build time. However, with the Module
                Federation Plugin, Webpack has an advantage of Run-Time
                integration, as well, which makes components are more decoupled.
              </p>
            </li>
            <li>
              <h3>
                <a href="https://single-spa.js.org/" target="blank">
                  Single SPA
                </a>
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://github.com/puzzle-js/puzzle-js" target="blank">
                  PuzzleJS
                </a>
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://github.com/opencomponents/oc" target="blank">
                  Open Components
                </a>
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://github.com/systemjs/systemjs" target="blank">
                  SystemJS
                </a>
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://github.com/umijs/qiankun" target="blank">
                  Qiankun
                </a>
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://github.com/smapiot/piral" target="blank">
                  Piral
                </a>
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://luigi-project.io/" target="blank">
                  Luigi
                </a>
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://github.com/frintjs/frint" target="blank">
                  FrintJS
                </a>
              </h3>
            </li>
            <li>
              <h3>
                <a href="https://github.com/zalando" target="blank">
                  Mosaic 9
                </a>
              </h3>
            </li>
          </ol>
        </section>
        <section
          id="references"
          className="resume-section p-3 p-lg-5 justify-content-center"
        >
          <h2>Additional Reads</h2>
          <ul>
            <li>
              <a
                href="https://www.linkedin.com/pulse/micro-frontends-from-begining-expert-rany-elhousieny-phd%E1%B4%AC%E1%B4%AE%E1%B4%B0"
                target="blank"
              >
                Micro Frontends From Beginner to Expert
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/pulse/micro-frontends-what-why-how-rany-elhousieny-phd%E1%B4%AC%E1%B4%AE%E1%B4%B0/"
                target="blank"
              >
                Micro Frontends What, Why, and How
              </a>
            </li>
            <li>
              <a
                href="https://medium.com/nerd-for-tech/micro-front-ends-hands-on-project-63bd3327e162"
                target="blank"
              >
                Understanding Micro Frontends With a Hands-On Example Using
                React, Webpack 5, and Module Federation
              </a>
            </li>
            <li>
              <a
                href="https://medium.com/nerd-for-tech/micro-frontends-hands-on-example-using-react-webpack-5-and-module-federation-adding-a-third-2fe8c61a73f"
                target="blank"
              >
                Micro Frontends Hands-On Example Using React, Webpack 5, and
                Module Federation: Adding a third React Micro Frontend
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/pulse/micro-frontends-hands-on-example-using-react-webpack-rany"
                target="blank"
              >
                Micro Frontends Step by Step Using React, Webpack 5, and Module
                Federation
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/pulse/deploying-micro-frontends-aws-step-using-gitlab-react-rany"
                target="blank"
              >
                Deploying Micro Frontends to AWS Step by Step Using React,
                Webpack 5, and Module Federation
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/pulse/understanding-micro-frontends-webpack5-configurations-rany"
                target="blank"
              >
                Understanding Micro-Frontends Webpack5 configurations Step by
                Step
              </a>
            </li>
            <li>
              <a
                href="https://www.linkedin.com/pulse/understanding-micro-frontends-webpack5-module-step-rany"
                target="blank"
              >
                Understanding Micro-Frontends Webpack5 Module Federation
                configurations Step by Step
              </a>
            </li>

            <li>
              <a
                href="https://martinfowler.com/articles/micro-frontends.html"
                target="blank"
              >
                Micro Frontends by Martin Fowler
              </a>
            </li>
            <li>
              <a
                href="https://itnext.io/11-micro-frontends-frameworks-you-should-know-b66913b9cd20"
                target="blank"
              >
                11 Micro Frontends Frameworks You Should Know
              </a>
            </li>
            <li>
              <a
                href="https://medium.com/@lucamezzalira/micro-frontends-decisions-framework-ebcd22256513"
                target="blank"
              >
                Micro-frontends decisions framework
              </a>
            </li>
            <li>
              <a
                href="https://blog.bitsrc.io/why-frontend-developers-need-to-be-webpack-experts-32e734b6f04a"
                target="blank"
              >
                Why Frontend Developers Need to be Webpack Experts
              </a>
            </li>
            <li>
              <a
                href="https://medium.com/xgeeks/micro-frontends-at-scale-part-1-a8ab67bfb773"
                target="blank"
              >
                Micro-Frontends at scale (part 1)
              </a>
            </li>
            <li>
              <a
                href="https://medium.com/frontend-at-scale/frontend-architectural-patterns-backend-for-frontend-29679aba886c"
                target="blank"
              >
                Frontend Architectural Patterns: Backends-For-Frontends
              </a>
            </li>
          </ul>
        </section>
      </div>
    </Layout>
  );
};

export default Publications;
